import React from "react";

const Button = (props) => {
  const { label, onClick, customClass } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (typeof onClick === "string") {
    return (
      <a href={onClick} className={`button ${customClass}`}>
        {label.toUpperCase()}
      </a>
    );
  }

  return (
    <button onClick={handleClick} className={`button ${customClass}`}>
      {label.toUpperCase()}
    </button>
  );
};

export default Button;
