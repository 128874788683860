import React from 'react'

export const SectionWrapper = (props) => {
  const { className, sectionNumber, children } = props;
  return (
    <section id={`${sectionNumber}`} className={`section  ${className}`}>
      {children}
    </section>
  );
};
