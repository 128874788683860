import React, { useEffect } from "react";
import whatsappicon from "../../assets/svg/whatsapp.svg";
import logo from "../../assets/svg/logo.svg";
import facebookicon from "../../assets/svg/facebook.svg";
import instagramicon from "../../assets/svg/instagram.png";
import img2 from "../../assets/images/img_2.jpg";
import img3 from "../../assets/images/img_3.jpg";
import img4 from "../../assets/images/img_4.jpg";
import img5 from "../../assets/images/img_5.jpg";
import bjjImage from "../../assets/images/bjj-image.jpg";
import sukataLogo from "../../assets/images/sukata3.png";
import kickboxing from "../../assets/images/kick.jpg";
import okamiLogo from "../../assets/images/okami-logo.svg";
import { SectionWrapper } from "../../components/SectionWrapper";
import Slider from "react-slick";
import { Form } from "../../components/Form";
import { Header } from "../../components/Header";
import Button from "../../components/Button";
import schedule from "./schedule.json";

const carouselObj = [
  {
    image: img2,
    activityName: "funcional",
    action: "",
    background: "",
  },
  {
    image: img3,
    activityName: "cross training",
    action: "",
    background: "",
  },
  {
    image: img4,
    activityName: "metabolico",
    action: "",
    background: "",
  },
  {
    image: img5,
    activityName: "complemento",
    action: "",
    background: "",
  },
  {
    image: bjjImage,
    activityName: "jiu jitsu",
    action: "",
    background: sukataLogo,
  },
  {
    image: kickboxing,
    activityName: "kickboxing",
    action: "",
    background: okamiLogo,
  },
];

function Home() {
  const screenSize = () => {
    const windowScreenSize = window.screen.width;
    let slidesToShowByScreenSize;
    if (windowScreenSize < 900) {
      return (slidesToShowByScreenSize = 1);
    } else if (windowScreenSize < 1200) {
      return (slidesToShowByScreenSize = 3);
    } else {
      return (slidesToShowByScreenSize = 5);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenSize(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    className: "home__carousel-slide",
  };

  return (
    <div className="home">
      <SectionWrapper sectionNumber="1" className="home__section-1">
        <Header />
        <div className="home__section-1__container">
          <div className="home__section-1__title">
            <span>el cambio</span>
            <span>comienza hoy</span>
          </div>
          <div className="home__section-1__text-container">
            <div className="home__section-1__text">
              <Button
                label="Reserva tu turno"
                onClick="https://kongfitness.turnosweb.com/"
              />
              {/*  <span>info del gimnasio</span>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a
                galley.
              </p> */}
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper sectionNumber="activities" className="home__section-2">
        <Slider {...sliderSettings}>
          {carouselObj.map((obj) => {
            const { image, activityName, background } = obj;
            return (
              <div className="home__carousel-img">
                {background && (
                  <img
                    width="30%"
                    /* height="10%" */ style={{
                      position: "absolute",
                      right: "3%",
                      top: "2%",
                    }}
                    src={background}
                  />
                )}
                <img className="home__carousel-img__img" src={image} />
                <span className="home__carousel-img__text">{activityName}</span>
              </div>
            );
          })}
        </Slider>
      </SectionWrapper>
      <SectionWrapper
        sectionNumber="contact"
        className="home__section-schedule"
      >
        <div className="home__section-schedule-container">
          <div className="home__section-schedule__title-container">
            <h2 className="calendar__header">HORARIO</h2>
            <h3 className="calendar__sub-title">ACTIVIDADES</h3>
          </div>
          <div className="calendar">
            {schedule.days.map((day) => (
              <span className="calendar__day">{day.toUpperCase()}</span>
            ))}
            {schedule.activities.map((activity) => (
              <div className="calendar__hour">
                <div>{activity.label}</div>
                <div>{activity.time}</div>
              </div>
            ))}
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper sectionNumber="contact" className="home__section-3">
        <div className="home__section-3__container">
          {/*     <Form /> */}
          <div className="home__section-3__location">
            <h2>Ubicación</h2>
            <iframe
              className="home__section-3__location-frame"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5457.980435401746!2d-68.04450231715889!3d-38.96999393959872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x960a31697e9fe8eb%3A0xab69270fe8aea6ef!2sKonG!5e0!3m2!1ses!2sar!4v1656279164665!5m2!1ses!2sar"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <span className="home__section-3__address">
              amaranto suárez 1300 - Bº Belgrano - Neuquén Capital
            </span>
            <div className="home__section-3__social">
              <a href="https://wa.me/2995788534?text=Hola, quisiera más info sobre las actividades!">
                <img src={whatsappicon} />
              </a>
              <a href="https://www.facebook.com/KonG.Fitnqn">
                <img src={facebookicon} />
              </a>
              <a href="https://instagram.com/_kongfitness?igshid=MzRlODBiNWFlZA==">
                <img width="22px" height="22px" src={instagramicon} />
              </a>
            </div>
          </div>
        </div>
        <footer className="home__footer">
          Copyright &copy; 2018-2023{" "}
          <img width="18px" height="18px" src={logo} /> KonG
        </footer>
      </SectionWrapper>
    </div>
  );
}

export default Home;
