import React from "react";
import { animateScroll } from "react-scroll";
import logo from "../../assets/svg/logo.svg";

export const Header = () => {
  return (
    <nav className="nav">
      <img className="nav__logo" src={logo} />
      <ul>
        <li>
          <a onClick={() => animateScroll.scrollTo(780)} smooth={true}>
            actividades
          </a>
        </li>
        <li>
          <a href="https://kongfitness.turnosweb.com/" target="_blank">
            turnos
          </a>
        </li>
        <li>
          {" "}
          <a onClick={() => animateScroll.scrollTo(1520)} smooth={true}>
            horario
          </a>
        </li>
        <li>
          {" "}
          <a onClick={() => animateScroll.scrollTo(2800)} smooth={true}>
            contacto
          </a>
        </li>
      </ul>
    </nav>
  );
};
